.CustomerDetails {
  &__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    h4,
    p {
      display: inline-block;
      margin-bottom: 0;
    }
    &--pets {
      display: block;
      ul {
        padding: 0 0 0 5px;
        li {
          list-style: none;
          display: flex;
          margin-bottom: 0.75rem;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          p {
            margin: 0;
            margin-left: 0.5rem;
            &:first-child {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
