.ant-progress {
  .ant-progress-inner {
    background-color: #ebebeb;
  }
  &.dark-trail {
    .ant-progress-inner {
      background-color: #d8d8d8;
    }
  }
}
