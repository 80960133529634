@import '../../../styles/variables.scss';

.sectionColumn {
  padding: 1rem;
  height: 100%;
  h3,
  h4 {
    font-weight: bold;
    text-transform: initial;
  }
}

.sectionColumnMain {
  padding: 1rem;
  height: fit-content;
  h3,
  h4 {
    font-weight: bold;
    text-transform: initial;
  }
  h3 {
    margin: 4px 0;
    font-size: 16px;
    line-height: 1.2;
  }
  h4 {
    margin: 0;
  }
}

.formInput {
  height: 40px;
  position: relative;
  margin: 8px 0;

  input {
    padding: 0 12px !important;
    outline: 0;
    font-size: 14px;
  }

  &__select {
    width: 100%;
    display: block;
  }

  &__prefix {
    white-space: nowrap;
    font-size: 14px;
    padding: 0 12px;
    color: #b3b3b3;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    z-index: 10;
    line-height: 1;
    &--active {
      transform: translate(4px, 4px) scale(0.75);
      & + input {
        padding: 8px 12px 0 12px !important;
      }
    }
  }
  &:focus-within {
    input {
      padding: 8px 12px 0 12px !important;
    }
    .formInput__prefix {
      transform: translate(4px, 4px) scale(0.75);
    }
  }
}

.listCard {
  border-radius: 8px !important;
  h4 {
    margin: 0;
  }
  i {
    font-size: 24px;
    color: $black;
  }
  svg {
    cursor: pointer;
  }
  &:first-child {
    margin-right: 0.5rem;
  }
  &:last-child {
    margin-left: 0.5rem;
  }
  input {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-color: #e8e8e8;
    height: 50px !important;
    padding: 0 16px;
    font-size: 14px;
    + span {
      border-radius: 0;
      // border-right: none !important;
      border-color: #e8e8e8 !important;
    }
  }
  &__wrap {
    // min-height: 50px;
    // max-height: 275px;
    height: 275px;
    overflow-y: scroll;
    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--collapse {
      background-color: $white;
      border-radius: 0 0 8px 8px;
      box-shadow: none !important;
    }
  }
  &__item {
    height: 50px;
    padding: 0 16px;
    &--disclaimer {
      display: flex;
      align-items: center;
      svg {
        margin-left: 8px;
      }
    }
    p {
      margin: 0;
    }
    &--associated {
      background-color: #efefef;
    }
  }
}

.buttonModalCreate {
  border: 1px solid #333;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  // cursor: pointer;
  outline: none;
}

.buttonNoBorder {
  border: none;
  top: 4px;
}

.buttonSaveChanges {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.coverageWrapper {
  position: relative;
  border-top: 1px solid #ebebeb;
  z-index: 9;
}

.loading {
  &__select h3 {
    margin: 16px 16px 16px 0;
    width: auto !important;
    height: 24px !important;
  }
  &__text h3 {
    margin: 8px 16px 8px 0;
    width: auto !important;
    height: 24px !important;
  }
}
