.ant-drawer-title h1 {
  margin: 0;
}
.ant-drawer-header {
  background: $light-gray;
  box-shadow: inset 0 -1px 0 0 #999999;
}

.ant-drawer-close {
  color: $black;
}

.ant-drawer-body {
  padding: 0;
  overflow: scroll;
  padding-bottom: 53px;
}
