@import '@/src/styles/variables.scss';

.CustomDrawer {
  &__header {
    h2 {
      color: #0a0a0a;
      font-size: 20px;
      font-weight: bold;
      text-transform: initial;
      margin-bottom: 16px;
      display: inline-block;
    }
    h3 {
      color: #0a0a0a;
      font-weight: bold;
      font-size: 13px;
      text-transform: initial;
      margin: 0;
    }
    span {
      font-size: 13px;
      line-height: 1;
    }
    i {
      color: #fdab02;
      font-size: 18px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
  &__summary {
    // margin-top: 16px;
    &--col {
      span i {
        margin: 0;
      }
      &:last-of-type {
        span {
          font-weight: bold;
          color: #0a0a0a;
          font-size: 16px;
        }
      }
    }
  }
  &__box {
    border: 1px solid #ebebeb;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    ul {
      margin: 0;
    }
  }
  &__table {
    margin-bottom: 24px;
    th {
      color: #0a0a0a !important;
    }
    td {
      font-size: 13px !important;
      color: #333333;
    }
    &--no-header {
      margin-bottom: 0;
      thead {
        display: none;
      }
      td {
        border-top: 1px solid #ebebeb;
      }
    }
    &--even-gray {
      tr:nth-child(2n) {
        background-color: $lighter-gray;
      }
    }
  }
}
