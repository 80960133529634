.timeline-legend .ant-tooltip-inner {
  background-color: white;
  border-radius: 5px;
  border-color: #333333;
  border: 1px solid #333333;
}

.disabled-hall {
  display: flex;
  align-items: center;
  color: #0a0a0a;
  font-size: 15px;
  font-weight: bold;
}

@include mq('xl') {
  .disabled-hall {
    width: 31%;
    left: 34% !important;
  }
}

@include mq('fullhd') {
  .disabled-hall {
    width: 21%;
    left: 38% !important;
  }
}

.returned-items {
  max-width: 400px;
  .ant-tooltip-inner {
    background: white;
    padding: 0;
  }
  .ant-tooltip-arrow::before {
    background: white;
  }
  .ant-table table {
    border-radius: 4px;
    tr th {
      background: #333333;
      color: #fff;
    }
    td {
      border-bottom: none;
    }
  }
}
