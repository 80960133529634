@import '../../styles/variables.scss';

.disabledSection {
  position: absolute;
  width: 100%;
  height: calc(100% + 2px);
  background-color: rgba(217, 217, 217, 0.75);
  left: 0;
  top: -1px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 25px;
    margin-right: 10px;
    color: $super-dark;
  }

  &__text {
    font-weight: bold;
    margin: 0;
    color: $black;
  }
}

.removeBox {
  padding: 8px;
  border: 1px solid $gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin-top: 20px;

  &__text {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    padding: 0 8px;

    p {
      font-weight: bold;
      margin: 0 5px 0 0;
    }
    // span {

    // }
  }
}

.saveBar {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;

  &__container {
    // max-width: 1024px;
    padding: 20px 24px;
    width: 100%;
    text-align: end;
  }
}

.emptyTable {
  display: flex;
  justify-content: center;
  padding: 40px 0 16px;
  span {
    color: #b3b3b3;
  }
}

// .productsList {
//   height: auto;
//   margin-top: 40px;

//   .ant-list-header,
//   & > div {
//     label {
//       margin-right: 10px;
//     }

//     p {
//       margin: 0;
//     }
//   }

//   &__item {
//     justify-content: flex-start;

//     .anticon,
//     i,
//     img {
//       margin-right: 40px;
//       cursor: pointer;
//     }

//     .ant-checkbox-wrapper,
//     label {
//       margin-right: 25px;
//     }

//     p {
//       margin: 0;
//     }
//   }

//   &__image {
//     width: 60px;
//     height: 60px;
//     margin-left: 20px;
//     margin-right: 10px;
//     background-color: $light-gray;
//   }

//   ul {
//     height: fit-content;
//   }
// }
