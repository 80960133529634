@import '@/src/styles/variables.scss';

tr.ant-table-row.drop-over-downward td {
  border-bottom: 2px dashed $blue-zee;
}

tr.drop-over-upward td {
  border-top: 2px dashed $blue-zee;
}

.ant-col.delete-products {
  border: 2px solid rgb(235, 235, 235);
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
