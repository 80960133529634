.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 28px 0 12px !important;
}

.ant-menu.ant-menu-sub .ant-menu-item,
.ant-menu > .ant-menu-submenu > div,
.ant-menu > .ant-menu-item {
  padding-left: 12px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d1f2f7;
  width: 100%;
}

// ARROW WITHOUT BLUE HOVER
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-horizontal .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $black;
}

// OPEN SUBMENU WITHOUT BLUE HOVER
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $black;
}

// GRAY HOVER
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover,
.ant-menu .ant-menu-submenu-inline div:hover,
.ant-menu .ant-menu-submenu div:hover {
  background: #ebebeb;
  width: 100%;
}

// SIDER BORDER WHEN COLLAPSED
.ant-menu-inline-collapsed {
  width: 0;
  .ant-menu-item.ant-menu-item-selected {
    &::after {
      transform: scaleY(1) !important;
      opacity: 1;
      // right: 1px;
    }
  }
  .ant-menu-submenu.ant-menu-submenu-selected {
    position: relative;
    &::after {
      content: '';
      border-right: 3px solid #33d6ef !important;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -40px;
    }
    &:not(.ant-menu-horizontal) > .ant-menu-submenu-title {
      background-color: #d1f2f7;
    }
  }
  &-tooltip {
    height: 40px;
    padding-left: 0;
    left: 44px !important;
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      height: 40px;
      padding: 0 16px;
      background-color: white;
      display: flex;
      align-items: center;
      a {
        color: #0a0a0a;
      }
      img {
        display: none;
      }
    }
  }
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-menu-item {
  margin-left: 0 !important;
}

.ant-menu-item > a,
.ant-menu-submenu {
  color: $black;
}

.ant-menu:not(.ant-menu-inline-collapsed) {
  .ant-menu-item > a,
  .ant-menu-submenu span,
  .ant-menu-submenu-title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-menu-item > a:hover,
.ant-menu-submenu > div:hover {
  color: $black;
}

.ant-menu-item,
.ant-menu-submenu {
  img {
    margin-right: 12px;
  }
}

// ARROW ICON HOVER
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: $black !important;
}

// ARROW ICON BACKGROUND
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background: #0a0a0a;
}

.ant-menu-submenu .ant-menu-item {
  margin: 0;
}

li.submenu-submenu .ant-menu-submenu-title {
  margin: 0;
}

.ant-layout-sider-trigger {
  position: absolute;
  top: 0px;
  height: 42px;
  padding: 0 15px;
  text-align: right;
}

// ITEM HEIGHT
// .ant-menu-vertical > .ant-menu-item,
// .ant-menu-vertical-left > .ant-menu-item,
// .ant-menu-vertical-right > .ant-menu-item,
// .ant-menu-inline > .ant-menu-item,
// .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
// .ant-menu-sub.ant-menu-inline > .ant-menu-item,
// .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
//   height: 35px;
//   line-height: 35px;
// }

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

// SUBMENU ARROW POSITION
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  margin-left: 10px;
}

// SUBMENU POSITION
ul.ant-menu-sub li.ant-menu-item {
  padding-left: 32px !important;
}

.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item,
.ant-menu-submenu-inline li.submenu-submenu .ant-menu-submenu-title {
  padding-left: 40px !important;
}

.ant-menu-submenu-inline li.submenu-submenu ul.ant-menu-sub li.ant-menu-item {
  padding-left: 48px !important;
}

li.submenu-submenu ul.ant-menu-sub li.ant-menu-item {
  padding-left: 10px !important;
}

// PADDING CHECK
// .ant-menu-submenu-open .ant-menu-submenu-title {
//   margin-bottom: 0;
// }
// .ant-menu-submenu-title span span {
//   background-color: blue;
//   height: 40px;
//   display: inline-block;
// }
// .submenu-submenu .ant-menu-submenu-title {
//   background-image:
//     linear-gradient(to bottom,
//       rgba(240, 255, 40, 1) 0%,
//       rgba(240, 255, 40, 1) 100%),
//     linear-gradient(to bottom,
//       rgba(240, 40, 40, 1) 0%,
//       rgba(240, 40, 40, 1) 100%);
//   background-clip: content-box, padding-box;
// }

// .ant-menu-item {
//   background-image:
//     linear-gradient(to bottom,
//       rgba(240, 255, 40, 1) 0%,
//       rgba(240, 255, 40, 1) 100%),
//     linear-gradient(to bottom,
//       rgba(240, 40, 40, 1) 0%,
//       rgba(240, 40, 40, 1) 100%);
//   background-clip: content-box, padding-box;
// }
