.ant-btn {
  color: $white;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background: $super-dark;
  border-color: $super-dark;
  border-width: 2px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $white;
    background: $super-dark;
    border-color: $super-dark;
    opacity: 0.9;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  }
}

.ant-btn-primary {
  background-color: $blue-zee;
  border-color: $blue-zee;
  color: $black;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $blue-zee;
    border-color: $blue-zee;
    color: $black;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: $blue-zee;
    border-color: $blue-zee;
    opacity: 0.5;
  }
}

.ant-btn-secondary {
  background-color: #fff;
  border-color: $black;
  color: $black;
  &:hover,
  &:active,
  &.active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    background-color: #fff;
    color: $black;
  }
  &:focus {
    background-color: rgba(10, 10, 10, 0.12);
    color: $black;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: rgba(10, 10, 10, 0.12);
    opacity: 0.5;
  }
}

.ant-btn-secondary-copy {
  background-color: #fff;
  border-color: $black;
  color: $black;
  &:hover,
  &:active,
  &.active {
    background-color: #fff;
    color: $black;
  }
  &:focus {
    background-color: #fff;
    color: $black;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: #fff;
    opacity: 0.5;
  }
}

.ant-btn-expedition {
  background-color: $blue-zee;
  border: none;
  color: $black;
  font-size: 13px;
  padding: 0 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  &[ant-click-animating-without-extra-node='true']::after {
    display: none !important;
  }
  span {
    margin-left: 3px;
  }
  &.inactive {
    border-color: #b3b3b3;
    color: #b3b3b3;
  }
  &:hover,
  &:active,
  &.active {
    background-color: $blue-zee;
    color: $black;
    opacity: 0.7;
    box-shadow: none;
  }
  &:focus {
    background-color: $blue-zee;
    color: $black;
    opacity: 0.9;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: #0a0a0a;
    background-color: #33d6ef;
    border-color: #33d6ef;
    opacity: 0.5;
  }
  &.left {
    border-radius: 4px 0 0 4px;
    text-transform: capitalize;
    border-right-color: #333;
  }
  &.right {
    border-radius: 0 4px 4px 0;
    text-transform: capitalize;
    border-left: none;
  }
  &.undo {
    border: 1px solid #b3b3b3;
    background-color: transparent;
    color: $black;
    box-shadow: none;
  }

  // &.order-request {
  //   background-color: $black;
  //   color: $white;
  //   width: 100%;
  //   height: 52px;
  //   border-radius: 0 0 8px 8px;
  //   span {
  //     width: 100%;
  //     margin-left: 0;
  //   }
  //   &:hover,
  //   &:active,
  //   &.active {
  //     opacity: 1;
  //   }
  // }
}

.btn-expedition-filter {
  button {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 1px solid $super-dark;
    border-radius: 18px;
    color: $super-dark;
    padding: 5px 12px;
    margin: 0 12px 12px 0;
    width: auto;
    transition: all ease 0.3s;

    &.active {
      font-weight: bold;
      color: $white;
      background-color: $super-dark;
    }

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &.is-hidden {
    display: none;
  }
}

.ant-btn-alert {
  background-color: #fdab02;
  border-color: #fdab02;
  color: $black;
  border-radius: 4px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #fdab02;
    border-color: #fdab02;
    color: $black;
    box-shadow: none;
  }
  button[disabled],
  button[disabled]:hover,
  button[disabled]:focus,
  button[disabled]:active,
  button[disabled].active,
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: #fdab02;
    border-color: #fdab02;
    opacity: 0.5;
  }
}

.ant-btn-danger {
  background-color: $red;
  border-color: $red;
  color: $black;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $red;
    border-color: $red;
    color: $black;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: $red;
    border-color: $red;
    opacity: 0.5;
  }
}

.ant-btn-link {
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: transparent;
    border-color: transparent;
    color: inherit;
    box-shadow: none;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.5;
  }
  &.base {
    color: $black;
    padding: 0;
    text-transform: none;
    font-size: 0.9em;
    &.bigger-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 1.6em;
        display: flex;
      }
    }
  }
  &.order-timeline,
  &.order-return-modal {
    background-color: transparent;
    border-color: transparent;
    color: #333333;
    box-shadow: none;
    text-transform: none;
    font-weight: normal;
    padding: 0;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: inherit;
      box-shadow: none;
    }
  }
  &.order-return-modal span {
    text-decoration: underline;
  }

  &.ant-btn-unassociate {
    border: none;
    padding: 0;
    color: $black;
    background-color: transparent;
    margin-left: 8px;
    width: 120px;
  }
}

.ant-btn-select {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  text-transform: initial;
  padding: 0 12px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: transparent;
    border-color: transparent;
    color: inherit;
    box-shadow: none;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.5;
  }
  &.base {
    color: $black;
    padding: 0;
    text-transform: none;
    font-size: 0.9em;
    &.bigger-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 1.6em;
        display: flex;
      }
    }
  }
  &.order-timeline {
    background-color: transparent;
    border-color: transparent;
    color: #333333;
    box-shadow: none;
    text-transform: none;
    font-weight: normal;
    padding: 0;
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: transparent;
      border-color: transparent;
      color: inherit;
      box-shadow: none;
    }
  }
}

.ant-btn-dashed {
  background-color: transparent;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: transparent;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: transparent;
    opacity: 0.5;
  }
}

.ant-btn-filter {
  background-color: $light-gray;
  border-color: $light-gray;
  color: $black;
  border-radius: 20px !important;
  font-weight: 100;
  text-transform: capitalize;

  &.ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $filters-blue;
    border-color: $blue-zee;
    color: $black;
    border-radius: 20px;
    opacity: 0.8;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    color: $black;
    background-color: $filters-blue;
    border-color: $blue-zee;
    opacity: 1;
    border-radius: 20px;
  }
}

.ant-btn-associate {
  background-color: white;
  padding: 0 20px;
  height: 32px;
  color: $black;
  border: 1px solid $black;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  width: 120px;
  &.ant-btn:focus,
  &.ant-btn:active,
  &.ant-btn.active {
    color: $black;
    background: transparent;
    border-color: $black;
    opacity: 1;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  }
}

.ant-btn-icon {
  background-color: transparent;
  padding: 0 8px;
  line-height: 1;
  color: inherit;
  border: none;
  box-shadow: none;
  i {
    font-size: 18px;
    svg {
      display: block;
    }
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: transparent;
    color: $dark-gray;
    border: none;
    box-shadow: none;
  }
  &-disabled,
  &.disabled,
  &[disabled],
  &-disabled:hover,
  &.disabled:hover,
  &[disabled]:hover,
  &-disabled:focus,
  &.disabled:focus,
  &[disabled]:focus,
  &-disabled:active,
  &.disabled:active,
  &[disabled]:active,
  &-disabled.active,
  &.disabled.active,
  &[disabled].active {
    background-color: transparent;
    color: $medium-gray;
    border: none;
    box-shadow: none;
  }
}
