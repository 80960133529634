.search-product-border {
  border-radius: 16px;
  border: 7px solid #ebebeb;
  padding: 24px 14px;
  margin-top: 40px;
}

.dropdown-column {
  width: 18%;
  margin-right: 10px;
}

.select-hub {
  margin-right: 16px;

  .ant-select-selection {
    min-height: 44px;
  }
}

.hubList_selected {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding: 0;
  list-style: none;
}

.pillHubName {
  cursor: pointer;
  padding: 5px 30px 5px 10px;
  margin: 0 5px 5px 0;
  border-radius: 15px;
  transition: ease all 300ms;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;

  &:after {
    content: 'x';
    height: 20px;
    width: 20px;
    position: absolute;
    right: 6px;
    top: 6px;
    border-radius: 100%;
    font-size: 18px;
    line-height: 18px;
    background: #ccc;
    color: #fff;
  }

  &:hover:after {
    background: #000;
  }
}

.hideTable {
  display: none;
  min-width: 300px;
  .ant-spin-nested-loading {
    z-index: 1;
  }
}

.hideTable:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #333;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  position: absolute;
  top: -18px;
  z-index: 2;
  right: 50%;
  transform: translateX(50%);
}

.skudetails-btn {
  position: relative;
  margin: 0 5px;
}

.skudetails-btn:hover {
  cursor: pointer;
}

.stock-details-table > .hideTable {
  width: 435px;
}

.skudetails-btn:hover > .hideTable {
  display: block;
  position: absolute;
  background: #fff;
  z-index: 9;
  right: 50%;
  transform: translateX(50%);
  top: 24px;
}

.skudetails-btn .ant-table-thead > tr > th {
  background: #333333;
  color: #fff;
}
