.ant-skeleton-paragraph {
  margin: 4px 0;
}

.ant-skeleton-input {
  .ant-skeleton-paragraph {
    margin: 0;
    li {
      height: 28px;
    }
  }
}
