@import '../../../styles/variables.scss';

.invoicePill {
  border: 1px solid #000;
  background: none;
  border-radius: 23px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  i {
    font-size: 14px;
  }
  span {
    white-space: nowrap;
    line-height: 1;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &.resolved i {
    color: #10ce9f;
  }

  &.alert {
    border-color: #fdab02;
    color: #fdab02;
    i {
      color: #fdab02;
    }
  }

  &.return {
    border-color: #7850ba;
    color: #7850ba;
    i {
      color: #7850ba;
    }
  }

  &.cancel {
    border-color: #ff365c;
    color: #ff365c;
    i {
      color: #ff365c;
    }
  }

  &__icon {
    margin: 0 5px 0 0;
  }
}
