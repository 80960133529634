// Variables

// Primary
$blue-zee: #33d6ef;
$blue-zee-light: #6de1f2;

// Secondary
$super-dark: #333333;
$super-dark-light: #979797;

// Complementary
$lighter-gray: #f9f9f9;
$light-gray: #ebebeb;
$medium-gray: #b3b3b3;
$dark-gray: #737373;
$black: #0a0a0a;
$white: #ffffff;
$gray: #cccccc;
$purple-legacy: #af98d6;

// Alerts
$red: #ff365c;
$red-light: #ff5f7d;
$red-5: #fc97aa;
$red-lighter: #fad2d9;
$success: #50ebc5;
$darker-success: #61edae;
$lighter-success: #daf6ea;
$warning: #ecc844;
$pastel-warning: #fed580;

// Pastel
$pastel-blue: #8ce7f5;
$pastel-green: #8df6dc;
$pastel-red: #fea5b5;

//button filters
$filters-blue: #b7f6ff;

// Feedbacks
$baby-blue-zee: #d5eff2;
$baby-red: #ffe0e6;
$baby-green: #d1f5ed;
$baby-warning: #f3ebcf;
$blue-very-light: #d1f2f7;
$orange-very-light: #ffeecc;

// Breakpoints
$breakpoints: (
  'mobile': (
    max-width: 991px
  ),
  'xxs': (
    max-width: 359px
  ),
  'xs': (
    max-width: 575px
  ),
  'sm': (
    min-width: 576px
  ),
  'md': (
    min-width: 768px
  ),
  'lg': (
    min-width: 992px
  ),
  'xl': (
    min-width: 1200px
  ),
  'xxl': (
    min-width: 1600px
  ),
  'fullhd': (
    min-width: 1920px
  )
);
