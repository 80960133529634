@import '../../../styles/variables.scss';

.listCard {
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  svg {
    cursor: pointer;
  }
  &:first-child {
    margin-right: 0.5rem;
  }
  &:last-child {
    margin-left: 0.5rem;
  }
  &__wrap {
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: scroll;
  }
  &__item {
    height: 60px;
    padding: 0 16px;
    p {
      margin: 0;
    }
    &:hover {
      background-color: #f0fffe;
    }
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 13px;
  right: 0;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  color: $black;
}
