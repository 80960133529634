@import '@/src/styles/variables.scss';

.container {
  border: 2px solid $light-gray;
  border-radius: 8px;
  font-weight: bold;
  padding: 14px 24px;
  margin: 10px 0;
  font-size: 1.1em;
  h3,
  p {
    margin-bottom: 0;
    line-height: 1;
  }
  h3 {
    font-weight: bold;
    text-transform: capitalize;
  }
  .hoverLink {
    transition: 0.4s;
    &:hover {
      color: rgba(0, 0, 0, 0.575);
    }
  }
  .highlight {
    color: $blue-zee;
  }
}
