@import '../../../styles/variables.scss';

.EvolutionDiagram {
  display: flex;
  flex-direction: column;
  padding: 3em 8em 3em 7em;
  margin: 1em 0;
  border-radius: 8px;
  background-color: $light-gray;
  &__line {
    display: block;
    width: 100%;
    height: 4px;
    max-width: 1300px;
    margin: 0 auto;
    background-color: $black;
    position: relative;
    &--icon {
      position: absolute;
      left: -90px;
      top: -10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 85px;
      .line-icon {
        display: block;
        width: 16px;
        height: auto;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
    &--primary,
    &--secondary {
      .position-dot {
        position: absolute;
        top: -3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: inherit;
        z-index: 1;
        &::before {
          position: absolute;
          width: 200px;
        }
        &:first-child {
          left: -4px;
        }
      }
    }
    &--primary {
      .position-dot {
        &::before {
          top: -25px;
        }
        &:first-child {
          &::before {
            content: '1. Preparando o pedido';
          }
        }
        &:nth-child(2) {
          left: calc((100% / 7) * 5);
          &::before {
            content: '2. Saiu para entrega';
            left: -85px;
          }
        }
        &:nth-child(3) {
          left: calc((100% / 7) * 6);
          &::before {
            content: '3. Pedido chegando';
            left: -50px;
          }
        }
        &:nth-child(4) {
          right: -4px;
          &::before {
            content: '4. Pedido entregue';
            left: -15px;
          }
        }
      }
      .client-name {
        position: absolute;
        top: -5px;
        left: 15px;
        width: 100px;
        font-weight: 900;
        color: $black;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &--secondary {
      background-color: $dark-gray;
      .position-dot {
        &::before {
          bottom: -25px;
        }
        &:first-child {
          &::before {
            content: '1. Coletando';
          }
        }
        &:nth-child(2) {
          left: calc(100% / 7);
          &::before {
            content: '2. Coletado';
          }
        }
        &:nth-child(3) {
          left: calc((100% / 7) * 2);
          &::before {
            content: '3. Empacotado';
          }
        }
        &:nth-child(4) {
          left: calc((100% / 7) * 3);
          &::before {
            content: '4. Faturado';
          }
        }
        &:nth-child(5) {
          left: calc((100% / 7) * 4);
          &::before {
            content: '6. Separado';
          }
        }
        &:nth-child(6) {
          left: calc((100% / 7) * 5);
          &::before {
            content: '7. Em trânsito';
          }
        }
        &:nth-child(7) {
          right: -4px;
          &::before {
            content: '8. Entregue';
          }
        }
      }
    }
    &--fill {
      height: 40px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      .fill-bar {
        $color: rgba(255, 255, 255, 0.15);
        $angle: 45deg;
        position: relative;
        width: 0%;
        height: 100%;
        box-shadow: none;
        background-color: $blue-zee-light;
        background-image: linear-gradient(
          $angle,
          $color 25%,
          transparent 25%,
          transparent 50%,
          $color 50%,
          $color 75%,
          transparent 75%,
          transparent
        );
        background-size: 55px 55px;
        animation: progress-bar-stripes 2s linear infinite;
        transition: width 2s;
        &.started {
          width: 30px;
        }
        &.picked {
          width: calc(100% / 7 + 30px);
        }
        &.packed {
          width: calc((100% / 7) * 2 + 30px);
        }
        &.billed {
          width: calc((100% / 7) * 3 + 30px);
        }
        &.separated {
          width: calc((100% / 7) * 4 + 30px);
        }
        &.dispatched {
          width: calc((100% / 7) * 5 + 30px);
        }
        &.delivering {
          width: calc((100% / 7) * 6 + 30px);
        }
        &.delivered {
          width: 100%;
          background-image: none;
          animation: none;
          background-color: $success;
        }
        &.canceled {
          background-color: $red;
          background-image: none;
          animation: none;
          &::before {
            content: url('../../../assets/images/order/order-evo-canceled-flag.png');
            position: absolute;
            top: -15px;
            right: -15px;
          }
        }
      }
      .handled {
        width: 75.6%;
      }
      .position-time {
        position: absolute;
        top: 10px;
        left: -20px;
        display: none;
        &.is-active {
          display: block;
        }
        &:nth-child(2) {
          left: -20px;
        }
        &:nth-child(3) {
          left: calc((100% / 7) - 14px);
        }
        &:nth-child(4) {
          left: calc(((100% / 7) * 2) - 14px);
        }
        &:nth-child(5) {
          left: calc(((100% / 7) * 3) - 14px);
        }
        &:nth-child(6) {
          left: calc(((100% / 7) * 4) - 14px);
        }
        &:nth-child(7) {
          left: calc(((100% / 7) * 5) - 14px);
        }
        &:nth-child(8) {
          left: calc(((100% / 7) * 6) - 14px);
        }
        &:nth-child(9) {
          left: calc(99% - 14px);
        }
      }
    }
  }
}

@keyframes progress-bar-stripes {
  to {
    background-position: 40px 100%;
  }
}
