.ant-tag {
  border-radius: 15px;
  padding: 4px 16px;
  .anticon {
    background: $medium-gray;
    padding: 3px;
    border-radius: 50%;
    color: $white;
  }
  &.squared {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    line-height: 1;
    background-color: $light-gray;
    border: 0;
    height: 28px;
    font-size: 1em;
  }
  &.chips {
    padding: 4px 12px;
    border-color: $medium-gray;
    background: $white;
    margin-bottom: 5px;
  }
  &.status {
    text-transform: capitalize;
    border: none;
    padding: 4px 15px;
    width: 140px;
    text-align: center;
  }
  &.waiting {
    background-color: $light-gray;
  }
  &.processing,
  &.pending,
  &.pastel-blue {
    background-color: $pastel-blue;
  }
  &.handled {
    background-color: $blue-very-light;
  }
  &.delivering {
    background-color: $orange-very-light;
  }
  &.delivered,
  &.success,
  &.credited {
    background-color: $pastel-green;
  }
  &.canceled,
  &.broken_contract,
  &.invalidated,
  &.expired {
    background-color: $pastel-red;
  }
  &.active {
    background-color: $success;
  }
  &.warning,
  &.expiring {
    background-color: $pastel-warning;
  }
  &.banned {
    background-color: $red-5;
  }
  &.withdrawn {
    background-color: $darker-success;
  }
  &.null {
    background-color: #ccc;
  }
}
