.modalUploadZip {
  &__Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px 0;

    &__UploadWrapper {
      flex-direction: column-reverse !important;
    }
  }

  &__Title {
    font-size: 23px;
  }
}

.regionDropdown {
  width: 200px;
  margin-bottom: 20px;
}
