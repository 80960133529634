@import '@/src/pages/Hubs/Edit/EditHubs.module.scss';

.region__wrapper {
  display: flex;
  padding: 20px 10px;
}

.item__check {
  span {
    border-radius: 100% !important;
    height: 25px;
    width: 25px;

    span::after {
      display: none !important;
    }
  }
}

.listCard__loading {
  height: 50px;
  // border-bottom: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listCard__sectionTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
}

.listCard__sectionTitle,
.item__associated {
  background-color: $light-gray;
}

.item__associated {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  button {
    border: none;
    img {
      margin: 0 0 0 5px;
    }
  }
  &.listCard__item {
    border-bottom: 1px solid #fff;
    strong {
      text-align: right;
      margin-left: 8px;
      line-height: 1.2;
    }
  }
}

.modalWrapper {
  padding: 24px;
  display: block;
  text-align: center;

  .modalTitle {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 10px;
    font-weight: bold;
    // text-align: center;
  }

  .modalSubtitle {
    font-size: 13px;
    // text-align: center;
  }

  .modalInput {
    margin: 30px 0 20px;

    &__label {
      text-align: left;
      font-weight: bold;
    }

    &__input {
      border-radius: 0;
      margin: 5px 0 0;
    }
  }
}

.modalActions {
  display: flex;
  justify-content: center;
}
