h1,
h2,
h3 {
  text-transform: uppercase;
}
h1 {
  font-size: 20px;
  font-weight: 800;
}

h2 {
  font-size: 16px;
  font-weight: 600;
}
