.ant-input-number {
  width: 100%;
}

.ant-input-number.dark-input-number {
  line-height: 0;
  .ant-input-number-input {
    background-color: $light-gray;
  }
  .ant-input-number-handler-wrap {
    opacity: 1;
    background-color: $blue-zee;
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: $white;
    }
    .ant-input-number-handler {
      background-color: $blue-zee;
    }
    .ant-input-number-handler-down {
      border-color: $blue-zee;
    }
  }
}

.edit-size {
  .ant-form-item {
    margin-bottom: 0;
    width: 178px;
  }
}
