.chips {
  &.deliverers-chips {
    background-color: #ffeec8;
    border-color: #ffeec8;
    display: flex;
    justify-self: center;
    align-items: center;
    cursor: pointer;
  }
}

.deliverers-chips {
  &__legend {
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: black;
    margin-right: 8px;
    &.delivering {
      background-color: $blue-zee;
    }
    &.last-deliver {
      background-color: #820077;
    }
    &.returning {
      background-color: #ffa800;
    }
  }
  &__selected {
    font-weight: 900;
  }
  &__close {
    margin-left: 8px;
  }
}
