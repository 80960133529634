@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.line-divider {
  width: 100%;
  border-bottom: 2px;
  border-color: $black;
  box-sizing: border-box;
}

.divider-horizontal {
  margin: 0px;
  height: 2px;
  color: $black;
}

.line-title {
  font-size: 16px;
  font-weight: bold;
  color: $black;
  @include mq('lg') {
    font-size: 14px;
  }
  &__skeleton {
    .ant-skeleton-paragraph {
      margin: 0;
      li {
        height: 34px;
        margin-bottom: 16px;
        border-radius: 8px;
      }
    }
  }
}

.line-content__skeleton {
  .ant-skeleton-paragraph {
    margin: 0;
    li {
      height: 48vh;
      border-radius: 8px;
    }
  }
}

.reload_button {
  background-color: $white;
  border: 1px solid $black;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-left: 8px;
  display: flex;
  padding: 0;
  svg {
    margin: 5px;
  }
  &[disabled] {
    cursor: default;
    border-color: #ccc;
    svg path {
      fill: #ccc;
    }
  }
}

.reload_button--mobile {
  height: 36px;
  margin: 16px 0px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    margin-right: 10px;
  }
  &[disabled] {
    cursor: default;
    border-color: #ccc;
    svg path {
      fill: #ccc;
    }
  }
}

.badge:after {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 22px;
  height: 22px;
  background-color: $blue-zee;
  color: $black;
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  content: attr(data-count);
}

.line-collapse {
  background-color: $white;
  box-shadow: none !important;
  height: 100%;
  .ant-collapse-header {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px 0 0 !important;
    border-bottom: 2.5px solid #b3b3b3;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: #0a0a0a !important;
    .ant-collapse-extra {
      text-transform: initial;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.65);
    }
    .anticon {
      right: 8px !important;
    }
  }
  .ant-collapse-item {
    box-shadow: none !important;
    height: 100%;
    .ant-collapse-content {
      height: 100%;
      .ant-collapse-content-box {
        padding: 0 !important;
        height: 100%;
      }
    }
  }
}
