.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentBorder {
  border-radius: 16px;
  border: 7px solid #ebebeb;
  padding: 16px;
  min-height: 48px;
}

.inputTitle {
  font-weight: bold;
  margin-bottom: 8px;
}

.priceIndexSelect {
  & > div {
    height: 40px;

    & > div {
      line-height: 38px;
    }
  }
}

.filterButton {
  margin-right: 0 !important;
  &:disabled {
    background-color: #d9d9d9;

    &:hover {
      background-color: #d9d9d9;
    }
  }
}

// Modal
.modalCollapse {
  background-color: #f8f8f8;

  div[role='button'] {
    padding: 16px !important;
  }
}
.modalSelectContainer {
  margin-bottom: -8px;
  margin-top: 12px;
}
.modalFooterButtons {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
