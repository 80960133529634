.ant-list-items {
  height: 300px;
  overflow: auto;

  &:first-child {
    border-top: 1px solid #e8e8e8;
  }

  .ant-list-item-meta {
    align-items: center;
    display: flex;
    padding: 0 10px;

    .ant-list-item-meta-description {
      color: #333333;
    }
  }
}

.ant-list-bordered {
  .ant-list-items {
    height: fit-content;

    &:first-child {
      border: none;
    }
  }
}

.ant-list {
  &.even-gray {
    .ant-list-items > li.ant-list-item__green-bg {
      background-color: rgba(40, 168, 188, 0.3);
    }
    .ant-list-items > li:nth-of-type(2n) {
      background-color: $lighter-gray;
      &.ant-list-item__green-bg {
        background-color: #b2d8de;
      }
    }
  }
  &.ant-list-flex {
    .ant-list-items li {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
}

.list-sizes {
  .ant-list-item-meta {
    display: flex;
    align-items: center;
  }
  .ant-list-item-meta-title {
    line-height: 1;
  }
  .ant-list-item-meta-avatar {
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 3px;
  }

  .ant-list-item-meta-description {
    color: #b3b3b3;
    font-size: 12px;
    line-height: 0;
  }
}

.ant-list-reshipment-items {
  .ant-list-items {
    height: auto;
    border: none;
  }
}
