@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.counter {
  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      font-weight: bold;
      margin-right: 8px;
      white-space: nowrap;
    }
    .ant-input {
      padding: 4px 8px;
      text-align: center;
      max-width: 60px;
    }
    @include mq('lg') {
      flex-direction: column;
      justify-content: center;
      span {
        font-size: 11px;
        color: #333333;
        margin: 0;
      }
    }
  }
  &-button {
    border: none;
    background-color: transparent;
    color: #333333;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    &[disabled] {
      color: #ccc;
    }
    &__decrement {
      font-size: 24px;
    }
  }
  &-manual-collect {
    align-items: center !important;
  }
}
