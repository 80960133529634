@import '@/src/pages/Hubs/Edit/EditHubs.module.scss';

.hubsSuspension {
  background: $light-gray;

  .sectionColumn {
    background: $white;
    margin: 0 0 10px;
    border-radius: 0 0 5px 5px;
  }

  .suspensionTable {
    border-radius: 5px;
  }
}

.defaultSupension {
  &__input {
    border-radius: 0;

    span {
      font-weight: bold;
      display: block;
      margin-bottom: 4px;
    }
  }
}

.modalDelete {
  &__title,
  &__text {
    text-align: center;
  }

  &__text {
    margin: 20px 0;
    font-size: 14px;
  }
}

.buttonModalCreate {
  cursor: pointer;
}
