@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.card-single {
  width: 90%;
  height: auto;
  margin: 10px 0 10px 0;
  border-radius: 8px;
  box-shadow: none;
  transition: ease all 300ms;
  background-color: $white;
  .ant-btn-expedition {
    font-size: 14px;
    letter-spacing: 0.32px;
  }

  &.focused-card {
    box-shadow: 0px 2px 10px #999;
    transform: scale(1.05);
  }

  // &.summary-card:not(.disabled):not(.has-request) {
  &.summary-card:not(.has-request) {
    background-color: #f8f8f8 !important;
    font-size: 14px;
  }

  // &.disabled {
  //   pointer-events: none;
  //   filter: saturate(0.5);
  //   opacity: 0.5;
  // }

  .ant-skeleton-paragraph {
    margin: 8px 0;
    li {
      height: 13px;
      & + li {
        margin-top: 4px;
      }
    }
  }

  &__content {
    border-radius: 8px 8px 0 0;
    &:not(.no-footer) {
      border-bottom: none !important;
    }
    &.no-footer {
      border-radius: 8px;
      .card-section:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    border-radius: 0 0 8px 8px;
    border-top: none !important;
    &.no-border {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:hover:not(.order-request) {
        opacity: 0.7;
      }
      .ant-btn-expedition {
        background-color: inherit;
        color: inherit;
        width: 100%;
        height: 52px;
        border-radius: 0 0 8px 8px;
        span {
          width: 100%;
          margin-left: 0;
        }
        &:hover,
        &:active,
        &.active {
          opacity: 1;
        }
      }
    }
    &.order-request {
      .ant-btn-expedition {
        width: 50%;
        &:first-of-type {
          border-bottom-right-radius: 0;
          background-color: $dark-gray;
        }
        &:last-of-type {
          border-bottom-left-radius: 0;
          background-color: #000;
        }
        &:hover {
          opacity: 0.7;
        }
        span {
          white-space: normal;
        }
      }
    }
    &--loading {
      height: 52px;
      display: flex;
      align-items: center;
    }
  }
}

.card-section {
  padding: 4px 12px;
  min-height: 48px;
  border-bottom: 1px solid #ebebeb;
  color: #737373;
  display: flex;
  align-items: center;
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &.space-between {
    justify-content: space-between;
  }
  svg {
    display: block;
  }
  &__drawer-btn {
    color: #333;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0px;

    &.cursor-none {
      cursor: none;
    }

    &.no-margin {
      margin-bottom: 0;
    }
  }
  &__order-number {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    margin-right: 4px;
  }
  &__date {
    font-size: 12px;
    white-space: nowrap;
  }
  &__eta {
    flex-grow: 1;
    &--text {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      & + div {
        line-height: 8px;
        margin-top: 6px !important;
      }
    }
    &--sale-channel {
      margin-left: 12px;
    }
    .ant-progress-outer,
    .ant-progress-inner {
      display: block;
    }
  }
  &__scheduled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &--text {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #121212;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &__hub {
    &--text {
      margin-left: 8px;
      color: #333;
      height: 20px;
    }
  }
  &__user {
    margin-left: 8px;
    display: flex;
    align-items: center;
    &--img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-shadow: 0 0 1px 1px #ccc;
      margin-right: 8px;
      object-fit: cover;
      &.hidden {
        display: none;
      }
    }
    &--text {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      span + span {
        color: #333;
      }
    }
  }
  &__address {
    margin-left: 8px;
    max-width: calc(100% - 40px);
    span {
      font-size: 12px;
      display: inline-block;
    }
  }
  &__volumes {
    display: flex;
    span {
      margin-left: 8px;
      font-size: 12px;
    }
  }
  &__nfe {
    max-width: 60%;
    span {
      font-size: 12px;
      color: #333 !important;
    }
  }
  &__alert {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #333;
    span {
      margin: 4px 0;
    }
  }
}

// .card-time {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   .text-bold-black {
//     font-size: 13px;
//   }
// }

// .order {
//   &-number {
//     font-size: 16px;
//     text-align: start;
//     line-height: 1;
//     max-width: 65%;
//     overflow-wrap: anywhere;
//     // font-size: 18px;
//     // @include mq('lg') {
//     //   font-size: 20px;
//     // }
//   }

//   &-time {
//     color: #333;
//     font-size: 12px;
//   }

//   &-value {
//     margin-right: 10px;
//   }
// }

// .card-description {
//   display: flex;
//   flex-direction: column;
//   margin: 4px 0;
//   color: #333;
//   font-size: 13px;
// }

// .button {
//   &-remove {
//     outline: none;
//     background-color: transparent;
//     border: none;
//     cursor: pointer;
//   }

//   &-showDrawer {
//     text-align: start;
//     cursor: pointer;
//     border: none;
//     outline: none;
//     background-color: transparent;
//     padding: 0px;
//     margin: 0 0 5px 0;

//     &.cursor-none {
//       cursor: none;
//     }

//     &.no-margin {
//       margin-bottom: 0;
//     }
//   }
// }

.cancellation-modal {
  &__content {
    text-align: center;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @include mq('mobile') {
      margin-top: 50%;
    }
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    color: #0a0a0a;
  }

  &__description {
    width: 56%;
    margin-bottom: 30px;
  }
}

.card-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue-zee;
  &__label {
    font-size: 15px;
    margin-left: 5px;
    font-weight: bold;
    @include mq('lg') {
      font-size: 14px;
    }
  }
}

.card-details {
  border-radius: 0 0 8px 8px;
  border: 1px solid rgb(235, 235, 235) !important;
  border-top: none !important;
  font-size: 12px;
  background-color: #f8f8f8;
  box-shadow: none !important;
  .ant-collapse-header {
    padding: 10px !important;
    height: auto;
    display: block;
    border-bottom: none;
    margin-bottom: 0;
    color: #333 !important;
    font-weight: normal !important;
    font-size: 14px;
    text-transform: initial;
    .anticon {
      right: 16px !important;
    }
  }
  &__content-box {
    display: flex;
    flex-direction: column;
  }
  @include mq('mobile') {
    .ant-collapse-item {
      box-shadow: none !important;
      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
  @include mq('lg') {
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}

.has-request .card-details {
  background-color: #c5f7fc;
  border-top: 2px solid #33d6ef !important;
}
