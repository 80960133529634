@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.ant-modal-expedition.ant-modal {
  @include mq('mobile') {
    transform-origin: unset !important;
    width: 100% !important;
    max-width: unset;
    height: 100%;
    margin: 0;
    top: 0;
    padding: 0;
  }

  .ant-modal-content {
    background-color: #fff !important;
    border-radius: 0 !important;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    @include mq('lg') {
      border-radius: 8px !important;
      display: block;
      height: auto;
    }
    .ant-modal-header {
      @include mq('lg') {
        border-radius: 8px 8px 0 0 !important;
      }
      .ant-modal-title {
        &__wrapper {
          display: flex;
          justify-content: space-between;
          @include mq('lg') {
            position: relative;
          }
        }
        &__subtitle {
          display: flex;
          align-items: center;
          margin-top: 8px;
          &--has-pill {
            flex-direction: column;
            align-items: flex-start;
            @include mq('lg') {
              flex-direction: row;
              align-items: center;
            }
          }
        }
        &__print {
          border: 1px solid #333;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          i {
            color: #333;
            font-size: 20px;
            padding: 6px;
          }
          @include mq('lg') {
            position: absolute;
            top: 0;
            right: 24px;
          }
        }
        &__info {
          font-size: 14px;
          font-weight: normal;
          display: inline-block;
          width: 100%;
          line-height: 1;
          @include mq('lg') {
            font-size: 16px;
            margin-right: 4px;
            width: auto;
            line-height: 22px;
          }
        }
        &__pill {
          font-size: 12px;
          line-height: 1.5;
          margin-top: 8px;
          @include mq('lg') {
            min-width: 100px;
            margin-left: 20px;
            margin-top: 0;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }

    .ant-modal-body {
      flex-grow: 1;
      padding: 0 0 70px;
      @include mq('lg') {
        padding: 0;
      }
      .modal-table {
        &__wrapper {
          margin: 24px 0;
          border: 1px solid #efefef;
          @include mq('lg') {
            border: none;
          }
        }
        &__header {
          display: block;
          background-color: #ebebeb;
          border-radius: 8px 8px 0 0;
          font-weight: bold;
          color: #0a0a0a;
          text-align: center;
          &-cell {
            border-left: 1px solid #fff;
            padding: 10px 12px;
          }
        }
        &__content {
          margin: 16px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          @include mq('lg') {
            margin: 16px;
            border: none;
          }
          &--no-margin-mo {
            margin: 0;
            .modal-table__wrapper {
              margin: 0;
            }
            @include mq('lg') {
              margin: 16px;
              .modal-table__wrapper {
                margin: 24px 0;
              }
            }
          }
        }
        &__row {
          background-color: #fff;
          text-align: center;
          padding: 12px 4px;
          border: none;
          @include mq('lg') {
            border: 1px solid #efefef;
            padding: 16px 4px;
            &:last-child {
              border-radius: 0 0 4px 4px;
            }
          }

          .ant-col {
            padding: 0 4px;
            span {
              line-height: 1.2;
              margin: 4px 0;
            }
          }
          &:nth-child(odd) {
            background-color: #ebebeb;
          }
          &.collect-table {
            .ant-checkbox-inner {
              border-width: 1px;
            }
            .ant-col:nth-child(1) {
              order: 2;
            }
            .ant-col:nth-child(2) {
              order: 3;
            }
            .ant-col:nth-child(3) {
              order: 1;
            }
            .ant-col:nth-child(4) {
              order: 4;
            }
            @include mq('lg') {
              .ant-col:nth-child(1) {
                order: 1;
              }
              .ant-col:nth-child(2) {
                order: 2;
              }
              .ant-col:nth-child(3) {
                order: 3;
              }
              .ant-col:nth-child(4) {
                order: 4;
              }
              .ant-col:nth-child(5) {
                order: 5;
              }
              .ant-col:nth-child(6) {
                order: 6;
              }
            }
          }
          &.pack-table {
            .modal-table__counter {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              @include mq('lg') {
                justify-content: flex-end;
                align-items: center;
              }
            }
          }
          &.devolution-table {
            .modal-table__product {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              &-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                @include mq('mobile') {
                  line-height: 1.2;
                  span {
                    margin: 4px 0;
                  }
                }
              }
            }
            .modal-table__counter {
              display: flex;
              align-items: center;
              border-top: 1px solid #ebebeb;
              margin: 12px -4px 0;
              padding-top: 12px;
              .ant-col {
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                  font-weight: bold;
                }
              }
            }
            &:nth-child(odd) {
              .modal-table__counter {
                border-color: #fff;
              }
            }
          }
        }
        &__col {
          &--responsive {
            @include mq('lg') {
              width: 12.5% !important;
              display: block !important;
            }
          }
        }
        &__alert {
          display: flex;
          flex-direction: column-reverse;
          button {
            margin: 8px 0;
          }
          @include mq('lg') {
            flex-direction: row;
            button {
              margin: 0 8px;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      background-color: #fff;
      position: fixed;
      border-top: 2px solid #e8e8e8;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 70px;
      @include mq('lg') {
        text-align: right;
        position: unset;
        border-top: none;
        height: auto;
        width: auto;
        display: block;
        padding: 0 23px 23px;
      }
      .ant-btn {
        width: 90%;
        padding: 8px 16px;
        height: auto;
        @include mq('lg') {
          padding: 0 16px;
          width: auto;
          height: 32px;
        }
      }
    }

    .ant-modal-footer > div {
      display: flex;
      justify-content: center;
    }
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
}
