.pack-square-btn {
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  &[disabled] {
    border-color: #ccc !important;
    svg path {
      fill: #ccc;
    }
  }
}
