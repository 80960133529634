@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.volumes-select {
  width: 100% !important;
  height: 100%;
  .ant-select-selection {
    height: 100%;
  }
  &__dropdown {
    min-width: 150px !important;
    @include mq('mobile') {
      right: 20px;
      left: unset !important;
    }
    .ant-select-dropdown-menu {
      padding: 0;
    }
    .ant-select-dropdown-menu-item-group-title {
      font-size: 14px;
      padding: 5px 12px;
      color: #fff;
      background-color: #737373;
      line-height: 1.5;
      height: auto;
    }
    &--option {
      color: #333;
      line-height: 1.5;
      padding-left: 12x !important;
      + .volumes-select__dropdown--option {
        border-top: 1px solid #ebebeb;
      }
      &:hover {
        background-color: #ebebeb !important;
      }
      &.ant-select-dropdown-menu-item-selected {
        background-color: #fff;
        font-weight: normal;
      }
    }
  }
}
