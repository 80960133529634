.ant-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  .ant-card-head-title {
    font-weight: bold;
  }
  .ant-card-head {
    border: 0;
  }
  .ant-card-body {
    padding-top: 5px;
  }
  &.pb-5 .ant-card-body {
    padding-bottom: 5px;
  }
  &.p-0 .ant-card-body {
    padding: 0;
  }
  &.small-title {
    .ant-card-head {
      height: 50px;
      padding: 0 16px;
      .ant-card-head-title {
        text-transform: inherit;
        font-size: 14px;
      }
      .ant-card-extra {
        padding: 0;
      }
    }
  }
  &.normal-title {
    .ant-card-head {
      border-bottom: inherit;
    }
    .ant-card-head-title {
      text-transform: capitalize;
    }
  }
  &.tags-card {
    .ant-card-head-title {
      flex: unset;
      h2 {
        margin: 0;
      }
    }
    .ant-card-extra {
      float: unset;
      margin-left: 16px;
      // min-height: 62px;
      .ant-tag {
        margin-bottom: 0 !important;
      }
    }
  }
}

.ant-card.disabled {
  .ant-card-body {
    background: #ebebeb;
  }
}

.ant-card.space-left {
  .ant-card-body {
    padding-left: 32px;
  }
}
.ant-card.no-shadow {
  box-shadow: none;
}

.ant-card.no-padding .ant-card-body {
  padding-left: 0;
  padding-right: 0;
}

.ant-card.light-shadow {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.07);
}

.ant-tabs .ant-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

// Page Customer Info
.car-access {
  .ant-card {
    box-shadow: none;
  }
  .ant-card-body {
    padding: 5px;
  }
}

.car-wallet {
  .ant-card-head {
    min-height: 0;
  }
  .ant-card-body {
    padding: 0 24px 10px;
  }
  .ant-card-head-title {
    padding: 10px 0 0px;
  }
  .ant-card-extra {
    padding: 10px 0 0px;
  }
}

.car-addresses {
  .ant-card {
    box-shadow: none;
    margin: 8px;
  }
  .ant-card-body {
    padding: 10px;
  }
}

.card-timeline {
  .ant-card-body {
    padding: 0 23px 23px;
  }
}

.ant-card-extra:hover {
  cursor: pointer;
}

.list-sizes {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    border: 1px solid #f8f8f8;
    border-radius: 8px 8px 0 0;
    background: #f8f8f8;
  }
}
