.timeline-order {
  .ant-timeline-item-tail {
    border-left: 2px solid #ebebeb;
  }
  .ant-timeline-item-head-blue {
    color: #999999;
    border-color: #ffffff;
    background-color: #999999;
  }
  .ant-timeline-item-pending .ant-timeline-item-head {
    background-color: transparent;
  }
  .ant-timeline-item-last {
    padding: 0;
  }
  &.dispatch {
    .timeline-order__event {
      margin: 0;
      font-weight: bold;
      font-family: proxima-nova, sans-serif;
      font-size: 14px;
    }
  }
}

.timeline-order-input {
  .ant-input-search-enter-button + .ant-input-group-addon,
  &.ant-input-search-enter-button input + .ant-input-group-addon {
    border: 1px solid $medium-gray;
    border-left: none;
    padding-right: 1px;
  }
  .ant-input-search-enter-button
    + .ant-input-group-addon
    .ant-input-search-button,
  &.ant-input-search-enter-button
    input
    + .ant-input-group-addon
    .ant-input-search-button {
    border-radius: 10px;
    padding: 7px 16px;
    border: 3px solid #fff;
    background-color: #333333;
    color: #ffffff;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 4px);
  }
  .ant-input {
    height: 44px;
    &:hover,
    &:focus {
      + .ant-input-group-addon {
        border-color: #5dedfc;
        border-right-width: 1px !important;
        outline: 0;
      }
    }
    &:focus {
      + .ant-input-group-addon {
        box-shadow: 3px 0px 0px 2px rgba(51, 214, 239, 0.2);
      }
    }
  }
}

.timeline-wallet {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 24px;
  .ant-timeline-item-last {
    padding-bottom: 0;
    .ant-timeline-item-tail {
      display: block;
    }
  }
  .ant-timeline-item-tail {
    top: 14px;
    height: calc(100% - 18px);
    border-left: 1px solid #b3b3b3;
  }
  .ant-timeline-item-head-red {
    color: #ff365c;
    border-color: #ff365c;
    background-color: #ff365c;
  }
  .ant-timeline-item-head-green {
    color: #10ce9f;
    border-color: #10ce9f;
    background-color: #10ce9f;
  }
  .ant-timeline-item-content {
    h3 {
      text-transform: capitalize;
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
    span {
      color: #737373;
      position: absolute;
      top: 0;
      right: 0;
      line-height: 24px;
      font-size: 13px;
    }
    p {
      color: #333;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
