.ant-icon-check-circle {
  font-size: 25px;
  position: absolute;

  svg {
    background-color: $success;
    border-radius: 50%;
    fill: $success;

    path:first-of-type {
      fill: $black;
    }
  }
}

.ant-badge {
  margin: 0 !important;
  span {
    margin: 0 !important;
  }
  .anticon.dispatch-gift {
    background-color: $blue-zee;
    border-radius: 50%;
    padding: 4px;
    top: 4px;
    right: 2px;
    &__collect {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      top: 0;
    }
  }
}

.anticon.order-item-gift {
  background-color: $blue-zee;
  padding: 4px;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  left: 0;
}
