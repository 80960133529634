.select-search {
  width: 100%;
  .ant-select-selection {
    padding-top: 3px;
    min-height: 44px;
    border-radius: 5px 0px 0px 5px;
    border-right: none;
  }
}

.modal-sizes {
  .ant-select-selection {
    min-height: 80px;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    overflow: visible;
    white-space: normal;
    text-overflow: clip;
  }
}
