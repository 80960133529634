@import '../../styles/variables.scss';

.shippingList {
  &__modal {
    text-align: center;
    p {
      padding: 0 24px 20px;
      margin: 0;
    }
  }
  &__table {
    i {
      font-size: 18px;
      color: #333333;
    }
  }
  &__drawer {
    &--user {
      h3 {
        font-weight: bold;
        font-size: 13px;
        color: #0a0a0a;
        text-transform: initial;
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 4px;
        font-size: 13px;
        color: #333;
      }
    }
  }
}

.box {
  border-radius: 16px;
  background-color: $light-gray;
  min-height: 48px;
}

.section {
  background-color: $white;
  border-radius: 16px;
  border: 7px solid $light-gray;
  padding: 20px 16px;
  width: 100%;
  position: relative;
  .borderTopNone {
    border-top: none;
  }
  &__mask {
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(235, 235, 235, 0.8);
    i {
      font-size: 24px;
      margin-right: 12px;
    }
    h2 {
      margin: 0;
      text-transform: initial;
      color: #0a0a0a;
    }
  }
}

.title {
  margin-left: 10px;
  text-transform: inherit;
}

.resetButton {
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.modalContent {
  // padding: 16px;
  height: 100px;

  &__title {
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
}

.input {
  margin-top: 10px;

  &__label {
    font-weight: bold;
    font-size: 13px;
  }

  &__select {
    margin: 20px 0 5px 0;
  }
}

.option {
  color: $blue-zee;
}
