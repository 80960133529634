@import '@/src/styles/variables.scss';

tr.ant-table-row.drop-over-downward td {
  border-bottom: 2px dashed $blue-zee;
}

tr.drop-over-upward td {
  border-top: 2px dashed $blue-zee;
}

.ant-col.delete-products {
  border: 2px solid rgb(235, 235, 235);
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.tooltip-table.ant-tooltip.ant-tooltip-placement-top {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      text-align: center;
    }
  }
}

.tooltip-buttons.ant-tooltip.ant-tooltip-placement-bottom {
  .ant-tooltip-content {
    .ant-tooltip-arrow::before {
      text-align: center;
      background-color: #ebebeb !important;
    }
    .ant-tooltip-inner {
      text-align: center;
      background-color: #ebebeb !important;
      color: #000000;
      box-shadow: none;
    }
  }
}
