@import '../../../styles/variables.scss';

.PromotionCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px $medium-gray;
  color: $super-dark;
  margin-top: 2em;
  max-height: 270px;
  min-height: 250px;

  a {
    color: $super-dark;
  }
  &__Header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.25rem;
  }
  &__Content {
    padding: 0.25rem 1rem 0.5rem;
    h3 {
      font-weight: 900;
      margin: 0;
      text-transform: capitalize;
      button {
        color: $black;
        padding: 0;
        height: auto;
        border: none;
        span {
          white-space: normal;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    .FoundPromotion {
      margin: 0 10px;
      font-weight: 600;
      color: #28a8bc;
    }
    p {
      margin: 0;
    }
  }
  &__Footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid $light-gray;
    font-size: 13px;
    margin-top: auto;
    h4 {
      color: $dark-gray;
      font-weight: 700;
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}

.system {
  background: $super-dark;
  border-color: $super-dark;
  color: #fff;
  h3 button,
  h4 {
    color: #fff;
  }
}
