@import '../../styles/variables.scss';

.container {
  background: $light-gray;
  padding: 8px;
  border-radius: 8px;
}

.Order {
  position: relative;
  min-height: 400px;
  &__order-info {
    position: absolute;
    z-index: 10;
    background: $super-dark;
    padding: 3px 8px;
    color: $light-gray;
    border-radius: 4px;
    font-weight: 500;
    margin-bottom: 0;
    top: 10px;
    left: 10px;
    line-height: 1.1;
    &--date {
      color: $medium-gray;
      font-weight: 300;
      font-size: 0.9em;
      margin-left: 5px;
    }
  }
}
