.ant-upload.ant-upload-drag {
  background: transparent;
  border: solid 1px $super-dark;
  border-style: dashed;
  .ant-upload.ant-upload-btn {
    padding: 1em 1.5em;
  }
  p.ant-upload-drag-icon {
    margin-bottom: 0;
  }
  .ant-upload-drag-icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: $super-dark;
    font-weight: bold;
    small {
      font-weight: 300;
    }
    svg {
      fill: $super-dark;
      width: 25px;
    }
    i {
      background: $medium-gray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      width: 50px;
      align-items: center;
    }
  }
}
