.imageWrapper {
  width: 150px;
  height: 150px;
  margin: 0 auto 16px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  img {
    width: auto;
    height: 100%;
  }
  i {
    color: #ccc;
    font-size: 32px;
  }
}

.imageUpload {
  display: flex;
  justify-content: center;
  &__size {
    font-size: 13px;
    display: block;
    text-align: center;
  }
}

.formItem {
  margin-bottom: 8px;
  &__input {
    width: 100%;
    margin-right: 8px;
  }
  &__select {
    width: 50%;
  }
}
