.ant-notification-notice {
  .ant-notification-notice-message {
    font-weight: bold;
  }
  &.error {
    border: 1px solid $red;
    background: $red-lighter;
  }
  &.success {
    border: 1px solid $darker-success;
    background: $lighter-success;
  }
  &.warning {
    border: 1px solid $warning;
    background: $baby-warning;
    i {
      color: $warning;
    }
  }
}

.ant-notification {
  @include mq('mobile') {
    margin: 0 !important;
    right: 50% !important;
    transform: translateX(50%) !important;
  }
}
