@import '../../../styles/variables.scss';

.sectionColumn {
  padding: 1rem;
  height: 100%;
  h3,
  h4 {
    font-weight: bold;
    text-transform: initial;
  }
}

.formInput {
  height: 40px;
  position: relative;
  margin: 8px 16px 8px 0;

  input {
    padding: 0 12px !important;
    outline: 0;
    font-size: 14px;
  }

  &__prefix {
    white-space: nowrap;
    font-size: 14px;
    padding: 0 12px;
    color: #b3b3b3;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    z-index: 10;
    line-height: 1;
    &--active {
      transform: translate(4px, 4px) scale(0.75);
      & + input {
        padding: 8px 12px 0 12px !important;
      }
    }
  }
  &:focus-within {
    input {
      padding: 8px 12px 0 12px !important;
    }
    .formInput__prefix {
      transform: translate(4px, 4px) scale(0.75);
    }
  }
}

.listCard {
  h4 {
    margin: 0;
  }
  i {
    font-size: 24px;
    color: $black;
  }
  svg {
    cursor: pointer;
  }
  &:first-child {
    margin-right: 0.5rem;
  }
  &:last-child {
    margin-left: 0.5rem;
  }
  input {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-color: #e8e8e8;
    height: 50px !important;
    padding: 0 16px;
    font-size: 14px;
    + span {
      border-radius: 0;
      border-right: none !important;
      border-color: #e8e8e8 !important;
    }
  }
  &__wrap {
    // min-height: 50px;
    // max-height: 275px;
    height: 275px;
    overflow-y: scroll;
  }
  &__item {
    height: 50px;
    padding: 0 16px;
    &--disclaimer {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin: 0 0 0 5px;
      }
    }
    p {
      margin: 0;
    }
    &--associated {
      background-color: #efefef;
      border-bottom: 1px solid #fff !important;
    }
  }
}

.modalwrapper {
  padding: 20px 30px;
  text-align: center;

  &__title {
    font-size: 20px;
  }

  &__subtitle {
    font-size: 17px;
  }

  &__districtInput,
  &__citiesInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin: 0 0 20px;

    strong {
      margin-bottom: 10px;
    }
  }

  &__citiesInput {
    width: 50%;
  }

  &__actionBtns {
    display: flex;
    justify-content: space-around;
  }
}
