.ant-collapse,
.ant-collapse-borderless > .ant-collapse-item {
  box-shadow: inset 0 -1px 0 0 #ebebeb;
  border: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: bold;
  padding: 16px 37px;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 24px;
}

.ant-drawer-custom {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 16px 40px 16px 24px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 24px;
  }

  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 8px;
  }
}

.ant-collapse-header {
  &__hubs {
    background-color: #ebebeb;
    border-bottom: 1px solid #fff !important;
    color: #000000a6;
    font-weight: normal;
    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      color: #000000a6 !important;
      font-weight: normal !important;
      padding: 0 16px 0 40px !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
      li {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
}
