.ant-searchbar {
  .ant-input-search-enter-button + .ant-input-group-addon,
  &.ant-input-search-enter-button input + .ant-input-group-addon {
    border: 1px solid $medium-gray;
    border-left: none;
  }
  .ant-input-search-enter-button
    + .ant-input-group-addon
    .ant-input-search-button,
  &.ant-input-search-enter-button
    input
    + .ant-input-group-addon
    .ant-input-search-button {
    border-radius: 0px 4px 4px 0;
    padding: 9px 15px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 4px);
    i {
      height: 20px;
      width: 24px;
      svg {
        width: 20px;
        height: auto;
      }
    }
  }
  .ant-input {
    height: 44px;
    &:hover,
    &:focus {
      + .ant-input-group-addon {
        border-color: #5dedfc;
        border-right-width: 1px !important;
        outline: 0;
      }
    }
    &:focus {
      + .ant-input-group-addon {
        box-shadow: 3px 0px 0px 2px rgba(51, 214, 239, 0.2);
      }
    }
  }
}

.ant-searchbar-filter {
  .ant-input {
    border-radius: 0 4px 4px 0;
  }
}
.deliverers-searchbar {
  .ant-input.ant-input-lg {
    border-radius: 0;
  }
}

.price-indexing-search {
  .ant-input {
    padding-right: 11px !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
  .ant-input-search-icon {
    display: none;
  }
}

.sizes,
.hubs,
.shipping {
  .ant-input-group {
    display: block;
  }
}

.ant-input-clear-icon {
  font-size: 16px;
}
