.Drawer {
  &__table {
    &--summary {
      margin-bottom: 24px;
      .Drawer__table--row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ebebeb;
        padding: 5px 16px;
        &::before,
        &::after {
          display: none;
        }
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
        + .Drawer__table--row {
          border-top: none;
        }
      }
    }
  }
}
