.ant-checkbox-inner {
  border-radius: 2px;
  border: solid 2px $gray;
}

.ant-checkbox-wrapper {
  color: $super-dark;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: $super-dark;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent;
}

.customer-info-checkbox {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: $blue-zee;
      border-color: $blue-zee;
      width: 25px;
      height: 25px;
      border-radius: 20px;
    }
    .ant-checkbox-inner::after {
      margin-left: 2px;
      border-color: white;
    }
    &.ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: #e2e2e2;
      }
    }
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-inner {
    border-radius: 20px;
    width: 25px;
    height: 25px;
    border: solid 2px #cccccc;
  }
}
