.ant-input {
  border-color: $medium-gray;
  border-radius: 4px;
  &.input-alert {
    border-color: $red;
  }
  &-group-compact {
    .ant-select-lg {
      width: 20%;
      height: 44px;
    }
    .ant-searchbar {
      width: 80%;
      max-width: 700px;
    }
  }
}

.ant-searchbar-no-button {
  input {
    padding: 0 16px 0 48px !important;
  }
  .ant-input-suffix {
    right: unset;
    left: 16px;
    i {
      color: #e8e8e8;
      pointer-events: none;
      cursor: default;
    }
  }
}

.ant-searchbar-list {
  .ant-input-suffix {
    i {
      color: #e8e8e8;
      margin-right: 6px;
    }
  }
}
