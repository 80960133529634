.ant-radio-wrapper {
  &.radio-squared {
    border-radius: 4px;
    border: solid 2px #ebebeb;
    padding: 2px;
    font-weight: bold;
    font-size: 1.2em;
    margin-right: 12px;
    .ant-radio-inner {
      display: none;
    }
  }
  &-checked.radio-squared {
    border: solid 2px $blue-zee;
    background: $blue-zee;
  }
}
