@import '@/src/pages/Hubs/Edit/EditHubs.module.scss';

.defaultETA {
  display: flex;
  align-items: center;
  width: 50%;

  input {
    width: 200px;
    margin-right: 10px;
    border-radius: 0;
  }

  button {
    height: 40px;
  }
}

.searchRegion {
  width: 90%;

  .buttonModalCreate {
    position: relative;
    margin-left: 20px;
    background: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  button {
    padding: 5px 9px;
  }

  &__selectRegion {
    width: 250px;
  }
}

.formInput {
  height: 40px;
  position: relative;
  margin: 8px 16px 8px 0;

  input {
    padding: 8px 12px 0 12px !important;
    outline: 0;
    font-size: 14px;

    &:not([value='']) ~ span,
    &:focus ~ span {
      transform: translate(4px, 4px) scale(0.75);
      left: 0;
    }
  }

  &__select {
    width: 100%;
  }

  &__label {
    white-space: nowrap;
    font-size: 14px;
    padding: 0 12px;
    color: $medium-gray;
    pointer-events: none;
    position: absolute;
    left: 0;
    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    z-index: 10;
    line-height: 1;
  }
}

.modalDelete {
  &__title,
  &__text {
    text-align: center;
  }

  &__text {
    margin: 20px 0;
    font-size: 14px;
  }
}
