.ListTile__container {
  display: flex;
  align-items: center;
  height: fit-content;
  margin: 8px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  &--user,
  &--customer {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  a {
    margin-left: 6px;
    color: #333333;
    text-decoration: underline;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    line-height: 16px;
  }
  button {
    margin-left: 6px;
    text-decoration: underline;
    color: #333333;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
  }
}
