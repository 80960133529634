@import '../../../styles/variables.scss';

.deliverers-map-overlay,
.deliverers-map-overlay__last,
.deliverers-map-overlay__gray {
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border: 2px solid $blue-zee;
  border-radius: 4px;
}
.deliverers-map-overlay__gray {
  border-color: $medium-gray;
}
.deliverers-map-overlay__last {
  border-color: #820077;
}
.deliverers-map-overlay-image {
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.deliverers-map-overlay-data {
  width: 220px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 0.75rem;
  p,
  a {
    margin-bottom: 0.25rem;
  }
  a:hover {
    color: $black;

    span {
      color: $blue-zee;
    }
  }
  &-text {
    width: 100%;
    color: $black;

    span {
      font-weight: 900;
      margin-left: 5px;
      transition: 0.2s color;
    }
  }
  &-status {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      width: 120px;
      text-align: center;
      border-radius: 20px;
      padding: 5px 10px;
      margin: 0;
      background-color: #f0f0f0;
      &.status-delivering {
        background-color: $blue-zee;
      }
      &.status-last-delivery {
        background-color: rgba(130, 0, 119, 0.4);
      }
      &.status-gray {
        background-color: $medium-gray;
      }
    }
  }
}
