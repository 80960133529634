@import '../../../styles/variables.scss';

.sectionColumn {
  // padding: 1rem;
  height: 100%;
  h3,
  h4 {
    font-weight: bold;
    text-transform: initial;
  }
}

.formInput {
  height: 40px;
  position: relative;
  margin: 8px 0;

  input {
    border-color: #b3b3b3;
    padding: 8px 12px 0 12px !important;
    outline: 0;
    font-size: 14px;
    height: 40px;

    &:not([value='']) ~ .hub-hours-label,
    &:focus ~ .hub-hours-label {
      transform: translate(4px, 4px) scale(0.75);
      left: 0;
    }
  }

  &__select {
    width: 100%;
  }

  &__label {
    white-space: nowrap;
    font-size: 14px;
    color: $medium-gray;
    pointer-events: none;
    z-index: 10;
    line-height: 1;
    position: absolute;
    padding: 0 12px;

    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;

    &--active {
      transform: translate(4px, 4px) scale(0.75);
      // left: -4px;
    }
  }
}

.replaceSubmit {
  display: flex;
  align-items: center;
}
