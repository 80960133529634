@import '../../styles/variables.scss';

.saveBar {
  width: 100%;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;

  &__container {
    padding: 20px 24px;
    width: 100%;
    text-align: end;
  }
}

.emptyTable {
  padding: 40px 0 16px;
  p {
    text-align: center;
    color: #b3b3b3;
  }
}

.inputLoading {
  width: 250px;
  h3 {
    margin: 8px 0 !important;
    height: 24px !important;
  }
}

.tableEdit {
  &__button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
  &__label {
    font-weight: bold;
    text-transform: capitalize;
  }
}
