@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.expedition {
  border: none;
  padding: 0;
  margin: 0 0 65px;
  @include mq('lg') {
    display: flex;
    flex-direction: column;
    background-color: #ebebeb;
    border-radius: 8px;
    padding: 8px;
    margin: 0;
    min-height: calc(100% - 50px);
    &__card {
      padding: 16px;
      border-radius: 8px;
      background-color: $white;
      & + .expedition__card {
        margin-top: 8px;
      }
      &:last-child {
        flex-grow: 1;
      }
    }
  }
  @include mq('fullhd') {
    // min-height: calc(100% - 56px);
    // padding-top: 0;
    .ant-spin-nested-loading,
    .ant-spin-container,
    .slick-slider,
    .slick-list {
      min-height: 100%;
      // margin-top: 10px;
    }
    .slick-track {
      min-height: calc(100% - 10px);
    }
  }

  &__view-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq('lg') {
      justify-content: space-between;
    }
    &:before,
    &:after {
      display: none;
    }
    > div {
      display: flex;
    }
    .ant-btn-expedition {
      background-color: transparent;
      border: 1px solid #333;
      width: 100px;
      height: 44px;
      display: flex;
      justify-content: center;
      color: #333;
      svg path {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &.inactive {
        border-color: #b3b3b3;
        color: #333;
        &.left {
          border-right-color: #333;
        }
        svg path {
          fill: #b3b3b3;
        }
      }
      &.active,
      &:hover,
      &:focus {
        background-color: transparent;
        color: #333;
        border-color: #333;
        opacity: 1;
        svg path {
          fill: #333;
        }
      }
      span {
        margin-left: 8px;
      }
    }
  }

  &__hubs-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    h2 {
      margin: 0 0 8px;
      font-size: 14px;
      font-weight: normal;
    }
    .ant-row {
      width: 100%;
    }
    .ant-select {
      width: 100%;
      .ant-select-selection {
        padding: 0;
        .ant-select-selection__rendered {
          height: 32px;
          margin: 8px 30px 8px 8px;
          ul {
            height: 100%;
            li {
              height: 100%;
              margin: 0 8px 8px 0;
              background: white;
              border-color: #b3b3b3;
              border-radius: 16px;
              color: #333;
              padding: 4px 32px 4px 12px;
              display: flex;
              align-items: center;
              &.ant-select-search.ant-select-search--inline {
                padding-left: 0;
              }
              .ant-select-selection__choice__content {
                font-size: 13px;
              }
              .ant-select-selection__choice__remove {
                font-size: 11px;
                background: #b3b3b2;
                color: white;
                width: 19px;
                height: 19px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 8px;
                i svg {
                  display: block;
                }
              }
            }
          }
        }
        .ant-select-arrow {
          top: 50%;
          color: #333333;
        }
      }
    }
    .ant-select-selection--multiple .ant-select-selection__clear {
      top: 50%;
      right: 30px;
    }
    @include mq('lg') {
      align-items: flex-start;
      margin-bottom: 0;
    }
  }

  &__header {
    @include mq('mobile') {
      &--btn {
        margin: 0 auto;
      }
    }
    @include mq('lg') {
      margin-bottom: 20px;
      .page-title {
        margin: 0;
      }
    }
  }

  &__search {
    @include mq('mobile') {
      width: 100%;
    }
    @include mq('lg') {
      width: 600px;
      display: flex;
      align-items: center;
      .ant-input-group-wrapper {
        width: calc(100% - 70px);
      }
    }
    input::placeholder {
      font-size: 14px;
      @include mq('lg') {
        font-size: 16px;
      }
    }
    &--count {
      margin-top: 16px;
      display: block;
      line-height: 1;
    }
    &--hidden-results {
      margin-top: 16px;
      .ant-collapse-header {
        background: #ebebeb;
        padding: 8px 40px !important;
        border-radius: 5px !important;
      }
      .ant-collapse-content {
        border-top: none;
      }
      .ant-collapse-item {
        border: none;
      }
      .ant-collapse-content-box {
        padding: 10px 16px !important;
        // border-bottom: 1px solid #ebebeb;
        p {
          margin-bottom: 0;
          & + p {
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.filtered-tags {
  display: flex;
  align-items: center;
  margin-top: 12px;
  .ant-tag.chips {
    margin: 0;
  }
  @include mq('lg') {
    &.table-view {
      .expedition__search--count {
        display: none;
      }
      .ant-tag.chips {
        margin: 4px 8px 4px 0;
      }
    }
  }
}

.slick-track {
  margin: 60px 0 0 0;
  display: flex;
  @include mq('xs') {
    margin-top: 10px;
  }
  .slick-slide {
    display: flex;
    height: auto;
    padding: 0 8px;
    > div:first-of-type {
      width: 100%;
      min-height: 100%;
      @include mq('fullhd') {
        min-height: calc(100vh - 188px);
      }
    }
  }
}

.slick-list {
  margin: 0 -8px;
}

.slick-arrow {
  position: absolute;
  top: 30px;
  border: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;

  &:before,
  &:after {
    content: '';
    display: block;
    background: #555;
    width: 15px;
    height: 3px;
    position: absolute;
    opacity: 1;
  }

  &.slick-next {
    right: 0;

    &:before {
      transform: translate(7px, 3px) rotate(135deg);
    }

    &:after {
      transform: translate(7px, -6px) rotate(-135deg);
    }
  }

  &.slick-prev {
    left: 0;
    z-index: 2;

    &:before {
      transform: translate(7px, 3px) rotate(45deg);
    }

    &:after {
      transform: translate(7px, -6px) rotate(-45deg);
    }
  }

  &.slick-disabled {
    opacity: 0.25;

    &:before {
      opacity: 1;
    }
  }
}

.slick-dots {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 72px;
  padding: 3px 8px;
  box-shadow: 0 -2px 13px 0 rgba(0, 0, 0, 0.1);
  @include mq('xxs') {
    display: flex !important;
    height: 70px;
    padding: 6px 20px;
    overflow: scroll;
  }
  li {
    margin: 0 11px;
    width: 35px;
    .tab-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        margin-bottom: 4px;
        font-size: 10px;
      }
      &__button {
        height: 36px;
        width: 36px;
        color: #333;
        border: #979797 solid 1px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        &:before {
          content: none;
        }
      }
      &__badge:after {
        position: absolute;
        top: 18px;
        right: -3px;
        width: 12px;
        height: 12px;
        background-color: $blue-zee;
        border-radius: 50%;
        content: '';
      }
    }
    &.slick-active {
      .tab-bar {
        font-weight: bold;
        &__label {
          font-size: 11px;
        }
        &__button {
          background-color: #333;
          color: #fff;
          border: none;
          font-size: 14px;
        }
      }
    }
    &:nth-child(7),
    &:nth-child(8) {
      display: none;
    }
  }

  .backto_thepast {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    padding: 0;
    margin-right: 8px;
    width: 40px;
    padding: 4px;
    outline-color: #ebebeb;
    p {
      margin-bottom: 0;
      font-weight: bold;
      color: #000;
      font-size: 12px;
      line-height: 1;
      margin-top: 4px;
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 15px;

      &:after {
        text-align: center;
        font-size: 12px;
        width: 15px;
        height: 15px;
        right: 0px;
        top: 0px;
      }
    }
  }
  @include mq('lg') {
    display: none;
  }
}

.no-margin {
  .slick-track {
    margin-top: 0;
  }
  // .slick-arrow {
  //   padding: 10px 0;
  //   &.slick-prev {
  //     left: -9px;
  //     top: -8px;
  //   }
  //   &.slick-next {
  //     right: -9px;
  //     top: -8px;
  //   }
  // }
}

.no-slide {
  .slick-track {
    display: flex;
    flex-direction: column;
    .slick-slide {
      margin-bottom: 24px;
    }
  }
}

.ant-layout-sider-light,
.page-title {
  display: none;
  @include mq('lg') {
    display: block;
  }
}

.orders-assigned {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 0;
  }

  &__divider {
    margin: 5px 0 15px;
  }

  &__pills {
    display: flex;
    overflow: scroll;
    margin-bottom: 10px;
    .ant-skeleton-paragraph li {
      height: 22px;
    }
  }
}
