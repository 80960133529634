.modalCoverage {
  .ant-modal-body {
    padding: 20px !important;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
  h2 {
    margin: 0;
  }
}

.btnLeft,
.btnRight {
  display: flex;
  padding: 0 20px;
}

.btnLeft {
  justify-content: flex-start;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    font-size: 15px;
    color: #000;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.btnRight {
  justify-content: flex-end;
}

.modalButton {
  display: flex;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;

  &__icon {
    font-size: 20px;
    margin-right: 10px;
  }
}
