.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-bar {
  margin: 0;
  border: 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  border: 0;
  margin: 0 10px;
  min-width: 80px;
  text-align: center;
  background: #fff;
}

.ant-tabs.dark-bg {
  color: #fff;
  height: 100%;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  border-bottom: 6px solid $blue-zee;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: $black;
}

.color-black .ant-tabs-nav .ant-tabs-tab-active {
  color: $black;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
  font-weight: bold;
}

.ant-tabs.bordered {
  border: 1px solid $light-gray;
  border-left: none;
  border-right: none;
}

.ant-tabs-ink-bar {
  height: 4px;
}

.ant-tabs-content.ant-tabs-top-content {
  height: calc(100% - 44px);
}

.card-tabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar {
  .ant-tabs-tab {
    border: 1px solid $light-gray;
    margin: 0;
  }
}

.ant-tabs .ant-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 36px;
}
