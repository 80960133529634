@import './variables.scss';

.multiple-image-upload {
  position: absolute;
  top: 15px;
  right: 20px;

  &.download {
    right: 75px;
  }

  button {
    background: $white;
    color: $black;
    font-size: 20px;
    padding: 3px 10px;
    height: auto;
  }
}

.no-image {
  &-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    margin: 0 auto;
  }

  &-text {
    color: #b3b3b3;
    text-align: center;
    margin-left: 16px;
    // margin-bottom: 0;
    // margin-top: 10px;
  }
}

// .preview-image {
//   height: 109px;
//   width: 112px;
//   border-radius: 3px;
//   position: relative;
//   border: 1px solid $light-gray;
//   margin-bottom: 15px;
// }

.remove-preview-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -4px;
  left: 4px;
  background: $white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: 1px solid $light-gray;

  &:hover,
  &:active,
  &:focus {
    background: $white;
    border: 1px solid $light-gray;
  }
}

.multiple-preview-container {
  position: relative;
  margin: 8px 0 16px;
  display: inline-block;
}

.multiple-preview-list {
  overflow-x: scroll;
  white-space: nowrap;
  margin: 0 auto 8px;
  width: fit-content;

  &.multiple-preview-no-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.multiple-preview-image {
  width: 80px;
  height: 80px;
  border-radius: 3px;
  border: 2px solid $light-gray;
  background: $white;
  margin: 0 10px;
  position: relative;

  &::after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    border: 1px dashed $blue-zee;
    display: none;
  }

  &:hover,
  &:focus {
    border-color: $blue-zee;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.drop-over-right {
    &::after {
      display: block;
      right: -11px;
    }
  }
  &.drop-over-left {
    &::after {
      display: block;
      left: -11px;
    }
  }
}

.avatar-upload {
  width: auto;
  .ant-upload.ant-upload-select {
    width: auto;
    height: auto;
    border: none;
    margin-right: 12px;
    .ant-upload {
      padding: 0;
    }
  }
}

.modal-sizes {
  .ant-upload-picture-card-wrapper {
    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
    }
  }
}
