@import '../../../styles/variables.scss';

.OrderResume {
  // margin-left: 2px;
  + .OrderResume {
    margin-top: 10px;
  }
  &__order-price {
    padding-left: 8px;
    ul {
      padding: 0;
      li {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin-bottom: 4px;
        h4,
        p {
          margin: 0;
        }
        &:last-child {
          background: $light-gray;
          margin: 0 -10px;
          border-radius: 4px;
          h4 {
            margin: 5px 10px;
          }
        }
      }
    }
  }
  &__return-item {
    display: flex;
    justify-content: space-between;
    margin: 24px 0 12px;
    h3 {
      margin: 0;
    }
  }
  &__bag-details {
    .bag-details {
      &__bag {
        &--position {
          h4 {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            span {
              display: block;
              &.bag {
                &-icon {
                  width: 12px;
                  height: auto;
                  margin: 0 12px 2px 4px;
                  img {
                    display: block;
                    width: 100%;
                    height: auto;
                  }
                }
                &-id {
                  font-weight: 900;
                }
              }
            }
          }
          ul.bag-item-list {
            border-left: 1px solid $black;
            padding-left: 12px;
            margin-left: 12px;
            margin-top: 8px;
            li {
              list-style: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 12px 0;
              .bag-item {
                &-image {
                  width: 20%;
                  height: fit-content;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                  position: relative;
                  img {
                    display: block;
                    width: 100%;
                    height: auto;
                  }
                }
                &-text-info {
                  max-width: 250px;
                  width: 80%;
                  padding-left: 5px;
                  p {
                    margin: 0;
                    &:nth-child(2) {
                      font-weight: 900;
                      margin: 8px 0;
                      color: #0a0a0a;
                    }
                  }
                  div {
                    width: auto;
                    padding: 2px 10px;
                    background-color: $light-gray;
                  }
                }
                &-input-picker {
                  margin-left: 24px;
                  display: none;
                  p {
                    font-size: 16px;
                    font-weight: bold;
                    color: #0a0a0a;
                    margin-top: 12px;
                  }
                }
              }
            }
            .bag-item-list--alert {
              margin: -8px 0 4px;
              span {
                font-size: 12px;
                font-weight: bold;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
    &--with-item-picker {
      .bag-item-input-picker {
        display: block !important;
      }
    }
  }
  &__nfes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    border-top: 1px solid $medium-gray;
    p,
    h4 {
      margin: 0;
    }
    h4 {
      font-weight: 900;
    }
    &--info {
      display: flex;
      flex-direction: row;
      align-items: center;
      a {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    & + .OrderResume__nfes {
      border-top: none;
    }
  }
  &__return-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__modal-footer {
    border-top: 1px solid #ebebeb;
    margin: 24px -24px -24px;
    padding: 24px;
    h2 {
      text-transform: initial;
      margin: 0;
    }
  }
}

.ReturnSection {
  border-top: 6px solid #000;
  margin-top: 5px;
  &__date {
    font-size: 14px;
    font-weight: normal;
    margin-top: 4px;
    display: block;
    color: #b3b3b3;
    i {
      margin-right: 4px;
    }
  }
  &__details {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-bottom: 14px;
    }
  }
  &__cancelation {
    border-top-color: $red;
  }
}

.ReturnModal {
  .bag-item-list {
    .bag-item-image {
      width: 130px !important;
    }
    .bag-item-text-info {
      width: -webkit-fill-available !important;
      max-width: unset !important;
    }
  }
}
