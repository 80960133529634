.ant-pagination {
  .ant-pagination {
    &-item {
      &:hover {
        box-shadow: 2px 2px 5px $light-gray;
      }
    }
  }
}

// .ant-pagination {
//   &.ant-pagination.mini {
//     .ant-pagination {
//       &-item {
//         margin: 0 4px;
//         &:not(.ant-pagination-item-active) {
//           border-color: inherit;
//         }
//       }
//       &-prev,
//       &-next {
//         margin: 0 4px;
//         .ant-pagination-item-link {
//           border-color: inherit;
//         }
//       }
//     }
//   }
// }
