.filter-component {
  &__button {
    background-color: $super-dark;
    border-color: $super-dark;
    margin-right: 16px;
    &:hover {
      background-color: $super-dark-light;
      border-color: $super-dark-light;
    }
  }
}

.expedition {
  .filter-component__button {
    margin: 0 12px;
  }
}

.filters_search {
  .filter-component__button {
    margin: 0;
  }
}
