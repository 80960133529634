.ant-select-selection {
  border-color: $medium-gray;
}

.ant-select {
  &.ant-select-filter {
    .ant-select-selection {
      height: 44px;
      border-radius: 4px 0 0 4px;

      &__rendered {
        line-height: 44px;
      }
    }
  }
}

.sizes .ant-select-lg,
.hubs .ant-select-lg,
.shipping .ant-select-lg {
  .ant-select-selection--single {
    height: 44px;
  }
  .ant-select-selection__rendered {
    line-height: 42px;
  }
}

.ant-select-hub {
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 40px;
  }
}
