@import '@/src/styles/variables.scss';
@import '@/src/styles/_mixins.scss';

.container {
  min-height: 100vh;
}

.header {
  background: $blue-zee;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.logo {
  display: inline-block;
  max-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  width: 24px;
  margin-right: 10px;
}

.menuTitle {
  color: $black;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
}

.menuButton {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
  outline: none;
}

.headerMenu {
  color: $super-dark;
  cursor: pointer;
}
